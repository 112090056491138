import React, {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import BookingManager from '../services/BookingManager'
import IBooking from '../interfaces/IBooking'
import apiErrorHandler from '../services/ApiErrorHandler'
import {Button, Container} from '@material-ui/core'
import {BookingState} from '../enums/BookingState'
import moment from 'moment'
import Boxes from '../components/Boxes'
import {io, Socket} from 'socket.io-client'
import {BoxState} from '../enums/BoxState'
import styles from './ManageBooking.module.scss'
import BoxStateForm from '../components/BoxStateForm'
import EndedBooking from './EndedBooking'
import {Rating as RatingComp} from '@material-ui/lab'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFacebookSquare, faInstagramSquare,} from '@fortawesome/free-brands-svg-icons'
import Swal from 'sweetalert2'
import Layout from '../components/Layout'
import ExtensionForm from '../components/ExtensionForm'
import PinCodePopover from '../components/PinCodePopover'

const ManageBooking = () => {
  let secret = new URLSearchParams(useLocation().search).get('token')
  const [booking, setBooking] = useState<IBooking>()
  const [socket, setSocket] = useState<Socket>()
  const [openForm, setOpenForm] = useState<boolean>(false)
  const [pinCode, setPinCode] = useState<string|undefined>(undefined)
  const [isOneTimePin, setIsOneTimePin] = useState<boolean>(false)
  const [extensionFormVisible, setExtensionFormVisible] =
    useState<boolean>(false)
  const [finished, setFinished] = useState<boolean>(false)
  const [redirect, setRedirect] = useState<boolean>(false)
  const [activeBox, setActiveBox] = useState<number>()

  useEffect(() => {
    if (!secret) return
    ;(async () => {
      try {
        const { value: newToken } = await BookingManager.getTokenFromSecret(
          secret
        )
        localStorage.setItem('token', newToken)

        setBooking(await BookingManager.getBooking())

        setInterval(async () => {
          if (booking && booking.state === BookingState.ACTIVE) {
            setBooking(await BookingManager.getBooking())
          }
        }, 3000)
      } catch (e) {
        apiErrorHandler(e, {
          onUnauthorizedError: () => {
            localStorage.removeItem('token')
          },
          onNotFoundError: () => {},
          default: () => {
            Swal.fire({
              icon: 'error',
              title: 'Ups...',
              text: 'Leider ist etwas schiefgelaufen. Bitte versuche es noch einmal.',
            })
          },
        })
      }
    })()
  }, [secret])

  useEffect(() => {
    if (booking && booking.state === BookingState.ACTIVE) {
      const socket = io(`${process.env.REACT_APP_API_URL}`)
      socket.emit('register', {
        authToken: localStorage.getItem('token'),
      })
      socket.on('state', (payload) => {
        setBooking((prevBooking: any) => {
          return { ...prevBooking, boxes: payload.boxes }
        })
      })
      setSocket(socket)

      return () => {
        socket.off('state')
        socket.disconnect()
      }
    }
  }, [booking?.state])

  useEffect(() => {
    if (booking && (booking.state === BookingState.COMPLETED || booking.completedAt !== null)) {
      setFinished(true)
    }
  }, [booking])

  const startBooking = async () => {
    try {
      setBooking(await BookingManager.start())
      // window.location.reload()
    } catch (e) {
      apiErrorHandler(e, {
        onUnauthorizedError: () => {
          localStorage.removeItem('token')
        },
        onGoneError: () => {
          Swal.fire({
            icon: 'error',
            title: 'Ups...',
            text: 'Diese Buchung ist bereits abgelaufen. Bitte stell sicher, dass Du dem richtigen Link aus Deiner aktuellen E-Mail/SMS gefolgt bist.',
          })
        },
        onTooEarlyError: () => {
          Swal.fire({
            icon: 'error',
            title: 'Bald geht es los...',
            text: 'Du bist leider ein bisschen zu füh dran...bitte warte noch, bis Dein Buchungszeitraum beginnt.',
          })
        },
      })
    }
  }

  const completeBooking = async () => {
    Swal.fire({
      icon: 'info',
      title: 'Buchung beenden',
      text: 'Hast Du alles wieder zurück in die Boxen gelegt und alle Boxen geschlossen?',
      confirmButtonText: 'Buchung beenden',
      cancelButtonText: 'Abbrechen',
      showCancelButton: true,
    }).then(async (res) => {
      if (res.isConfirmed) {
        try {
          await setBooking(await BookingManager.complete())
          setFinished(true)
        } catch (e) {
          apiErrorHandler(e, {
            onUnauthorizedError: () => {
              localStorage.removeItem('token')
            },
            onGoneError: () => {
              Swal.fire({
                icon: 'error',
                title: 'Ups...',
                text: 'Diese Buchung ist bereits abgelaufen. Bitte stell sicher, dass Du dem richtigen Link aus Deiner aktuellen E-Mail/SMS gefolgt bist.',
              })
            },
            onTooEarlyError: () => {
              Swal.fire({
                icon: 'error',
                title: 'Bald geht es los...',
                text: 'Du bist leider ein bisschen zu füh dran...bitte warte noch, bis Dein Buchungszeitraum beginnt.',
              })
            },
            onForbiddenError: () => {
              Swal.fire({
                icon: 'info',
                title: 'Tür noch offen?',
                text: 'Bitte stell sicher, dass alle Türen der Box geschlossen wurden.',
              })
            },
          })
        }
      }
    })
  }

  const bookingStartable = () => {

    if (!booking) return false;

    const startDate = moment(booking.startDate);
    if (booking?.location?.lockType !== 'IGLOO') {
      startDate.subtract(15, 'minutes');
    }

    return (
      booking &&
      booking.state === BookingState.CREATED &&
      moment(Date.now()).isAfter(
        startDate
      )
    )
  }

  const bookingAmountFullfilled = () => {
    return booking && booking.boxes && booking.boxes.length === booking.numberOfBoxes
  }

  const bookingCompleteable = () => {
    return (
      booking &&
      !booking.completedAt &&
      (booking.state === BookingState.ACTIVE || booking.state === BookingState.OVERDUE) &&
      !booking.boxes.some((b) => b.state === BoxState.OPEN)
    )
  }

  const boxesActive = () => {
    return (
      booking && booking.boxes.length && !booking.completedAt && (booking.state === BookingState.ACTIVE || booking.state === BookingState.OVERDUE)
    )
  }

  const openBoxes = async (number: number) => {

    if (booking?.location.lockType === 'IGLOO') {
      // Show PIN code loading
      setPinCode('');
      setIsOneTimePin(false);
    }

    setActiveBox(number)
    const openResponse = await BookingManager.openBox(number)

    if (booking?.location.lockType === 'IGLOO') {
      // Show PIN code
      setPinCode(openResponse.pinCode);
      if (openResponse.isOneTimePin) {
        setIsOneTimePin(true)
      }
    }

    if (!booking?.boxes.find((box) => box.number === number)?.checked) {
      setOpenForm(true)
    }

    setBooking(await BookingManager.getBooking())
  }

  const handleBoxForm = async (
    damaged?: boolean,
    usable?: boolean,
    description?: string
  ) => {
    setOpenForm(false)
    if (activeBox) {
      await BookingManager.reportBox(
        activeBox,
        description || '',
        !!usable,
        !!damaged
      )
      setBooking(await BookingManager.getBooking())
    }
  }

  const handleCancellation = async () => {
    Swal.fire({
      icon: 'info',
      title: 'Stornierung',
      text: 'Sicher, dass Du Deine Buchung stornieren möchtest?',
      confirmButtonText: 'Stornieren',
      cancelButtonText: 'Abbrechen',
      showCancelButton: true,
    }).then(async (res) => {
      if (res.isConfirmed) {
        await BookingManager.cancel()
        window.location.href = 'https://sup-dudes.com/storno'
      }
    })
  }

  if (!booking)
    return (
      <Layout hideBackButton>
        <div className={styles.center}>
          <div className={styles.h1}>BUCHUNG</div>
          <div className={styles.h2}>
            Wir konnten deine Buchung nicht finden!
          </div>
        </div>
      </Layout>
    )

  if (redirect)
    return (
      <Layout hideBackButton>
        <EndedBooking />
      </Layout>
    )

  if (finished) {
    return (
      <Layout hideBackButton>
        <div
          style={{
            textAlign: 'center',
          }}
        >
          <h1>Miete beendet</h1>

          <p>
            Du hast Deine Miete erfolgreich beendet. Wir hoffen, Du hattest Spaß
            auf dem Wasser.
          </p>
          <p>
            Wie zufrieden warst Du? Klicke einfach auf eines der folgenden
            Symbole.
          </p>

          <RatingComp
            size={'large'}
            name="half-rating"
            precision={1}
            onChange={(event, newValue) => {
              if (newValue && newValue > 3) {
                window.location.href = 'https://sup-dudes.com/feedback'
              } else {
                window.location.href = 'https://sup-dudes.com/feedback-neg'
              }
            }}
          />

          <p>
            Wir danken Dir, dass Du unseren Service genutzt hast und hoffen,
            Dich bald wieder auf dem Wasser mit einem SUP-Board oder Kajak von SUP DUDES zu
            sehen.
          </p>

          <p>
            Keine Angebote verpassen!
            <br />
            <a href="https://sup-dudes.com/newsletter">
              Jetzt unseren Newsletter abonnieren
            </a>
          </p>

          <div
            style={{
              display: 'flex',
              width: '60%',
              marginLeft: '20%',
              flex: 1,
              justifyContent: 'space-between',
              fontSize: 50,
            }}
          >
            <a href="https://instagram.com/supdudes.de/">
              <FontAwesomeIcon icon={faInstagramSquare} />
            </a>
            <a href="http://www.facebook.com/SUP-DUDES-109440421477853">
              <FontAwesomeIcon icon={faFacebookSquare} />
            </a>
          </div>
        </div>
      </Layout>
    )
  }

  return (
    <Layout hideBackButton>
      {openForm && (
        <BoxStateForm
          onChangeState={handleBoxForm}
          boxClosed={
            booking.boxes.find((box) => box.number === activeBox)?.state ===
            BoxState.CLOSED
          }
        />
      )}
      {pinCode !== undefined && (
        <PinCodePopover
          pinCode={pinCode}
          isOneTimePin={isOneTimePin}
          onClose={() => setPinCode(undefined)}
        />
      )}
      {extensionFormVisible && (
        <ExtensionForm
          onClose={() => setExtensionFormVisible(false)}
          booking={booking}
        />
      )}
      <Container className={styles.container}>
        <div className={styles.h1}>Buchung</div>

        <div
          style={{
            textAlign: 'center',
            marginBottom: 20,
          }}
        >
          <strong>
            Mietstart: {moment(booking?.startDate).format('DD.MM.yyyy HH:mm')}{' '}
            Uhr
            <br />
            Mietende:{' '}
            {!booking.extendedTo &&
              moment(booking?.endDate).format('DD.MM.yyyy HH:mm')}
            {booking.extendedTo &&
              moment(booking?.extendedTo).format('DD.MM.yyyy HH:mm')}{' '}
            Uhr
          </strong>
        </div>

        {!bookingStartable() && booking.state === 'CREATED' ? (
          <div className={styles.h2}>
            <p style={{textAlign: "center"}}>
            Deine Buchung hat noch nicht begonnen.
            <br />
            <br />
            Bitte öffne diese Seite, sobald Dein Buchungszeitraum begonnen hat.<br/>
            Sobald Dein Buchungszeitraum beginnt, kannst Du hier die Miete
            starten (der Startbutton erscheint dann hier) oder bis zu 24 Stunden
            vorher stornieren.
            </p>
          </div>
        ) : (
          <>
            <div className={styles.text}>
              Bitte prüfe nach Entnahme das Material auf Vollständigkeit und auf
              vorhandene Schäden.
            </div>
            <div className={styles.bold}>
              WICHTIG: Dein Smartphone ist Dein Schlüssel für die Box. Bitte lasse es nicht in der Box zurück!
              {booking?.location?.lockType === 'IGLOO' && ' Für die Rückgabe erhälst Du einen neuen PIN! '}
            </div>
          </>
        )}

          <div className={styles.buttonContainer}>
        {bookingStartable() &&(
            <Button
              className={styles.button}
              style={{
                backgroundColor: ' #FEB8A1',
              }}
              onClick={startBooking}
            >
              Ausleihe starten
            </Button>
        )}

          </div>

        {boxesActive() ? (
          <Boxes onOpen={openBoxes} boxes={booking.boxes} location={booking.location} />
        ) : (
          <></>
        )}

        {booking.state === BookingState.ACTIVE &&
          booking.numberOfBoxes > booking.boxes.length &&
          booking.boxes.length > 0 && (
            <div
              style={{
                backgroundColor: '#89AEB3',
                padding: 10,
                borderRadius: 8,
              }}
            >
              <p>
                Leider konnten wir Dir nicht so viele SUP-Boards/Kajaks zur Verfügung
                stellen, wie Du gebucht hast. Das kann zwei Gründe haben:
                Entweder hat der Mieter vor Dir die Miete verspätet beendet oder
                ein SUP-Board/Kajak ist gerade beschädigt worden und kann deshalb
                nicht vermietet werden. In diesen Fällen prüfen wir natürlich
                sofort, ob wir Dir ein anderes SUP-Board/Kajak zur Verfügung stellen
                können. Leider sind alle SUP-Boards/Kajaks aktuell bereits vermietet.
              </p>
              <p>
                Via "SUPs/Kajaks suchen" kannst du bis zu 15 Minuten vor Mietbeginn
                nach weiteren Boards suchen, bis die von dir ursprünglich gebuchte
                Anzahl erreicht ist. Ab Beginn des Mietzeitraums können wir Dir
                leider nur die bis dahin verfügbare Anzahl an Boards anbieten.

                Wir berechnen Dir natürlich nur die SUP-Boards/Kajaks, die Du auch
                nutzen konntest.
              </p>
              <p>
                Ruf uns gerne an, damit wir eine Lösung finden können:<br />
                <a href="tel:+491627988629">+49 162 79 88 629</a>
              </p>
            </div>
          )}

        {booking.state === BookingState.ACTIVE &&
          booking.numberOfBoxes > booking.boxes.length &&
          booking.boxes.length === 0 && (
            <div
              style={{
                backgroundColor: '#89AEB3',
                padding: 10,
                borderRadius: 8,
              }}
            >
              <p>
                Es tut uns wirklich leid, dass wir Dir die gebuchten SUP-Boards/Kajaks
                nicht zur Verfügung stellen können. Das kann zwei Gründe haben.
                Entweder hat der Mieter vor Dir die Miete verspätet beendet und/oder
                SUP-Boards/Kajaks sind beschädigt worden und können deshalb momentan
                nicht vermietet werden. In diesen Fällen prüfen wir natürlich
                sofort, ob wir Dir ein anderes SUP-Board/Kajak zur Verfügung stellen
                können. Leider sind alle verfügbaren SUP-Boards/Kajaks aktuell bereits vermietet.
              </p>
              <p>
                Via "SUPs/Kajaks suchen" kannst du bis zu 15 Minuten vor Mietbeginn
                nach weiteren Boards suchen, bis die von dir ursprünglich gebuchte
                Anzahl erreicht ist. Ab Beginn des Mietzeitraums können wir Dir
                leider nur die bis dahin verfügbare Anzahl an Boards anbieten.
              <br/>
              <br/>
                Natürlich erstatten wir Dir sofort den bezahlten Betrag, solltest
                du keine Boards erhalten haben oder berechnen Dir nur
                die tatsächlich erhaltene Anzahl an Boards, sobald du die
                Miete über den Knopf unten beendet hast.
              </p>
              <p>
                Ruf uns gerne an, damit wir eine Lösung finden können:<br/>
                <a href="tel:+491627988629">+49 162 79 88 629</a>
              </p>
            </div>
          )}

        {booking.state === BookingState.ACTIVE && !bookingAmountFullfilled() &&   moment(Date.now()).isBefore(
          moment(booking.startDate)
        )&&(
          <div
            style={{
              textAlign: 'center',
              paddingTop: 20,
            }}
          >
          <Button
            className={styles.button}
            style={{
              backgroundColor: ' #FEB8A1',
            }}
            variant="contained"

            onClick={startBooking}
          >
            SUPs / Kajaks suchen
          </Button>
          </div>
        )}
        {booking.state === 'ACTIVE' && !booking.extendedTo && (
          <div
            style={{
              textAlign: 'center',
              paddingTop: 20,
            }}
          >
            <Button
              onClick={() => setExtensionFormVisible(true)}
              variant="contained"
              style={{
                backgroundColor: ' #FEB8A1',
              }}
              className={styles.button}
            >
              Miete verlängern
            </Button>
          </div>
        )}

        <div className={styles.buttonContainer}>
          {(booking.state === BookingState.ACTIVE ||
            booking.state === BookingState.OVERDUE) && (
            <Button
              disabled={!bookingCompleteable()}
              onClick={bookingCompleteable() ? completeBooking : () => {}}
              variant="contained"
              style={{
                opacity: bookingCompleteable() ? 1 : 0.2,
                backgroundColor: ' #FEB8A1',
              }}
              className={styles.button}
            >
              Ausleihe beenden
            </Button>
          )}

          {booking.state === BookingState.CREATED &&
            moment(booking.startDate).subtract('24', 'hours') > moment() && (
              <Button
                onClick={handleCancellation}
                variant="contained"
                style={{
                  backgroundColor: ' #FEB8A1',
                }}
                className={styles.button}
              >
                Buchung stornieren
              </Button>
            )}
        </div>
        <br />
        <br />
        <br />
      </Container>
    </Layout>
  )
}

export default ManageBooking
