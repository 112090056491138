import React, { useEffect, useState } from 'react'
import { Button, Container, TextField } from '@material-ui/core'
import BookingManager from '../services/BookingManager'
import moment from 'moment'
import Swal from 'sweetalert2'
import ReactPhoneInput from 'react-phone-input-material-ui'
import { formatDate, formatTime } from '../helper/dateHelper'
import Loading from '../components/Loading'
import { NotificationChannel } from '../enums/NotificationChannel'
import InfoText from '../components/InfoText'
import { BookingMode } from '../enums/BookingMode'
import styles from './Booking.module.scss'
import API from '../services/API'
import apiErrorHandler from '../services/ApiErrorHandler'
import Layout from '../components/Layout'

export default function IBooking() {
  const config = BookingManager.getBookingConfig()

  const [loading, setLoading] = useState<boolean>(false)

  const [firstName, setFirstName] = useState<string>(
    BookingManager.getBookingConfigElement('firstName', '')
  )
  const [lastName, setLastName] = useState<string>(
    BookingManager.getBookingConfigElement('lastName', '')
  )
  const [email, setEmail] = useState<string>(
    BookingManager.getBookingConfigElement('email', '')
  )
  const [mobile, setMobile] = useState<string>(
    BookingManager.getBookingConfigElement('mobile', '')
  )

  const [discountCode, setDiscountCode] = useState<string>(
    BookingManager.getBookingConfigElement('discountCode', '')
  )
  const [paymentMethod, setPaymentMethod] = useState<string>(
    BookingManager.getBookingConfigElement('paymentMethod', 'stripe')
  )
  const [notificationChannel, setNotificationChannel] =
    useState<NotificationChannel>(
      BookingManager.getBookingConfigElement(
        'notificationChannel',
        NotificationChannel.EMAIL
      )
    )

  const [paypalData, setPaypalData] = useState<any>(
    BookingManager.getBookingConfigElement('paypalData', undefined)
  )

  useEffect(() => {
    if (paymentMethod === 'paypal') {
      setPaypalData(undefined)
      window.setTimeout(() => {
        createPayment().then((data) => {
          setPaypalData(data)
          BookingManager.updateBookingConfig({
            paypalData: data,
          })
        })
      }, 500)
    }
  }, [paymentMethod])

  useEffect(() => {
    ;(window as any).paypal
      .Buttons({
        // Order is created on the server and the order id is returned
        createOrder: (data: any, actions: any) => {
          const cfg = BookingManager.getBookingConfig()

          if (
            cfg.firstName?.trim() === '' ||
            cfg.lastName?.trim() === '' ||
            cfg.email?.trim() === '' ||
            cfg.mobile?.trim() === ''
          ) {
            Swal.fire({
              icon: 'error',
              title: 'Ups...',
              text: 'Bitte stell sicher, dass alle Felder ausgefüllt sind.',
            })

            return
          }

          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: cfg.paypalData.amount,
                },
              },
            ],
          })
        },
        // Finalize the transaction on the server after payer approval
        onApprove: (data: any, actions: any) => {
          // Authorize the transaction
          actions.order.authorize().then((authorization: any) => {
            // Get the authorization id
            var authorizationID =
              authorization.purchase_units[0].payments.authorizations[0].id

            BookingManager.updateBookingConfig({
              paymentId: authorizationID,
            })

            window.location.href = '/finishBooking'
          })
        },
      })
      .render('#paypal-button-container')
  }, [])

  useEffect(() => {
    BookingManager.updateBookingConfig({
      firstName,
      lastName,
      email,
      mobile,
      notificationChannel,
      paymentMethod,
      discountCode,
    })
  }, [
    firstName,
    lastName,
    email,
    mobile,
    notificationChannel,
    paymentMethod,
    discountCode,
  ])

  useEffect(() => {
    ;(async () => {
      const cfg = BookingManager.getBookingConfig()
      if (cfg.discountCode) {
        try {
          const discount = await BookingManager.checkDiscountCode(
            cfg.discountCode
          )
          if (discount.amount === -100) {
            setPaymentMethod('free')
          }
        } catch (e) {
          setPaymentMethod('stripe')
        }
      }
    })()
  }, [discountCode])

  const createPayment = async () => {
    const cfg = BookingManager.getBookingConfig()

    if (!cfg.location) return

    if (cfg.discountCode) {
      try {
        await BookingManager.checkDiscountCode(cfg.discountCode)
      } catch (e) {
        Swal.fire({
          icon: 'error',
          title: 'Gutscheincode ungültig',
          text: 'Leider ist der von Dir eingegebene Gutscheincode nicht gültig.',
        })

        return
      }
    }

    const payload = {
      firstName: cfg.firstName || '',
      lastName: cfg.lastName || '',
      email: cfg.email || 'test@example.com',
      mobile: cfg.mobile || '+4911111111',
      phoneNumber: cfg.mobile || '+4911111111',
      numberOfBoxes: cfg.numberOfBoxes,
      notificationChannel: cfg.notificationChannel,
      startDate: formatDate(cfg.startDate),
      paymentType: cfg.paymentMethod,
      discountCode:
        cfg.discountCode && cfg.discountCode.trim() === ''
          ? null
          : cfg.discountCode,
    } as any

    if (cfg.mode === BookingMode.HOUR && cfg.slot) {
      payload.startTime = cfg.slot.id
      payload.duration = cfg.duration ? cfg.duration * 60 : 0
    } else if (cfg.mode === BookingMode.DAY && cfg.slot) {
      payload.startTime = cfg.slot.id
      payload.numberOfDays = cfg.numberOfDays
    } else throw new Error('Invalid booking mode')

    try {
      return await API.post(`locations/${cfg.location.id}/payment`, payload)
    } catch (e) {
      apiErrorHandler(e, {
        onConflictError: () => {
          Swal.fire({
            icon: 'error',
            title: 'Da war jemand schneller...',
            text: 'Leider stehen zu der von Dir gewünschten Zeit nicht mehr ausreichend SUP-Boards/Kajaks zur Verfügung.',
          })
        },
        default: () => {
          Swal.fire({
            icon: 'error',
            title: 'Ups...',
            text: 'Leider ist bei der Buchung etwas schiefgelaufen. Bitte versuche es noch einmal.',
          })
        },
      })
    }
  }

  const handleSubmit = async () => {
    if (paymentMethod !== 'free') {
      const { url, paymentId, sessionId } = await createPayment()
      BookingManager.updateBookingConfig({
        paymentId: JSON.stringify({
          paymentId,
          sessionId,
        }),
      })
      window.location.href = url
    } else {
      BookingManager.updateBookingConfig({
        paymentId: 'free',
      })
      window.location.href = '/finishBooking'
    }
  }

  if (loading)
    return (
      <Layout>
        <Loading />
      </Layout>
    )

  return (
    <Layout>
      <div>
        <Container className={styles.container}>
          <div className={styles.h1}>Buchung</div>
          <div className={styles.h2}>{config.location?.name}</div>
          <div className={styles.list}>
            Datum: {moment(config.startDate).format('DD.MM.yyyy')}
            <br />
            Zeitraum:&nbsp;
            {config.mode === BookingMode.DAY ? (
              <>1 Tag</>
            ) : (
              <>
                {formatTime(config.slot?.start)} -{' '}
                {config.slot?.end.getMinutes() === 0
                  ? Math.floor(config.slot?.end.getHours() - 0.25)
                  : config.slot && config.slot.end.getHours()}
                :
                {config.slot && config.slot?.end.getMinutes() === 0
                  ? '45'
                  : config.slot && config.slot.end.getMinutes() === 15
                  ? config.slot && config.slot.end.getMinutes() - 15 + '0'
                  : config.slot && config.slot.end.getMinutes() - 15}
                &nbsp;Uhr
                <br />
                Dauer: {config.duration}{' '}
                {config.duration === 1 ? 'Stunde' : 'Stunden'}
              </>
            )}
            <br />
            Anzahl Sups/Kajaks: {config.numberOfBoxes}
            <br />
            <br />
          </div>

          <div className={styles.formContainer}>
            <TextField
              className={styles.form}
              label="Vorname"
              variant="outlined"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />

            <TextField
              className={styles.form}
              label="Nachname"
              variant="outlined"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />

            <TextField
              className={styles.form}
              label="E-Mail"
              variant="outlined"
              value={email}
              type="email"
              onChange={(e) => setEmail(e.target.value)}
            />

            <ReactPhoneInput
              inputClass={styles.form}
              value={mobile}
              onChange={(e) => setMobile(`+${e}`)}
              component={TextField}
              country="de"
              inputProps={{
                label: 'Mobiltelefon',
                variant: 'outlined',
              }}
            />

            <InfoText>Bitte wähle aus, wie wir Dich erreichen können:</InfoText>

            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                border: 'solid',
                borderColor: '#89AEB3',
                justifyContent: 'space-between',
                borderRadius: 2,
                marginBottom: 15,
                lineHeight: '200%',
                marginTop: -20,
              }}
            >
              <div
                onClick={() =>
                  setNotificationChannel(NotificationChannel.EMAIL)
                }
                style={{
                  color:
                    notificationChannel === NotificationChannel.EMAIL
                      ? '#fff'
                      : '',
                  backgroundColor:
                    notificationChannel === NotificationChannel.EMAIL
                      ? '#89AEB3'
                      : '#fff',
                  width: '50%',
                  textAlign: 'center',
                  paddingTop: 5,
                  paddingBottom: 5,
                  cursor: 'pointer',
                }}
              >
                E-Mail
              </div>
              <div
                onClick={() => setNotificationChannel(NotificationChannel.SMS)}
                style={{
                  color:
                    notificationChannel === NotificationChannel.SMS
                      ? '#fff'
                      : '',
                  backgroundColor:
                    notificationChannel === NotificationChannel.SMS
                      ? '#89AEB3'
                      : '#fff',
                  width: '50%',
                  textAlign: 'center',
                  paddingTop: 5,
                  paddingBottom: 5,
                  cursor: 'pointer',
                }}
              >
                SMS
              </div>
            </div>

            {paymentMethod !== 'free' && (
              <>
                <InfoText>Bitte wähle Deine Zahlungsweise:</InfoText>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    border: 'solid',
                    borderColor: '#89AEB3',
                    justifyContent: 'space-between',
                    borderRadius: 2,
                    marginBottom: 25,
                    lineHeight: '200%',
                    marginTop: -20,
                  }}
                >
                  <div
                    onClick={() => setPaymentMethod('stripe')}
                    style={{
                      color: paymentMethod === 'stripe' ? '#fff' : '',
                      backgroundColor:
                        paymentMethod === 'stripe' ? '#89AEB3' : '#fff',
                      width: '50%',
                      textAlign: 'center',
                      paddingTop: 5,
                      paddingBottom: 5,
                      cursor: 'pointer',
                    }}
                  >
                    Kreditkarte
                  </div>
                  <div
                    onClick={() => setPaymentMethod('paypal')}
                    style={{
                      color: paymentMethod === 'paypal' ? '#fff' : '',
                      backgroundColor:
                        paymentMethod === 'paypal' ? '#89AEB3' : '#fff',
                      width: '50%',
                      textAlign: 'center',
                      paddingTop: 5,
                      paddingBottom: 5,
                      cursor: 'pointer',
                    }}
                  >
                    PayPal
                  </div>
                </div>
              </>
            )}

            <TextField
              className={styles.form}
              label="Gutscheincode"
              variant="outlined"
              value={discountCode}
              onChange={(e) => setDiscountCode(e.target.value)}
            />

            <div>
              <Button
                style={{
                  backgroundColor: ' #FEB8A1',
                  display: paymentMethod !== 'paypal' ? 'block' : 'none',
                }}
                className={styles.button}
                variant="contained"
                onClick={handleSubmit}
              >
                Jetzt kostenpflichtig buchen
              </Button>

              <div
                style={{
                  display:
                    paymentMethod === 'paypal' && paypalData ? 'block' : 'none',
                }}
              >
                Nicht wundern, wir buchen Dir zunächst den doppelten Betrag als
                Kaution ab.
              </div>
              <div
                id="paypal-button-container"
                style={{
                  display:
                    paymentMethod === 'paypal' && paypalData ? 'block' : 'none',
                }}
              />
            </div>

            <br />
          </div>
        </Container>
      </div>
    </Layout>
  )
}
