function formatTwoDigitNumber(n: number) {
  if (n >= 10) return n
  return `0${n}`
}

export function formatDate(d: Date | undefined) {
  if (d === undefined) return ''
  return `${d.getFullYear()}-${formatTwoDigitNumber(
    d.getMonth() + 1
  )}-${formatTwoDigitNumber(d.getDate())}`
}

export function formatTime(d: Date | undefined) {
  if (d === undefined) return ''
  return `${formatTwoDigitNumber(d.getHours())}:${formatTwoDigitNumber(
    d.getMinutes()
  )}`
}
