import BadRequestError from '../errors/BadRequestError'
import AuthenticationError from '../errors/AuthenticationError'
import GoneError from '../errors/GoneError'
import ForbiddenError from '../errors/ForbiddenError'
import InternalServerError from '../errors/InternalServerError'
import ConflictError from '../errors/ConflictError'
import NotFoundError from '../errors/NotFoundError'

export default class API {
  static async request(method: string, path: string, body?: any) {
    const opts: any = {
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    }

    const token = localStorage.getItem('token')
    if(token)
      opts.headers.Authorization = `Bearer ${token}`

    if (body) {
      for (const key in body) {
        if (body.hasOwnProperty(key)) {
          if (body[key] instanceof Date) {
            const date = body[key] as Date
            body[key] = `${date.getFullYear()}-${
              date.getMonth() + 1 < 10
                ? `0${date.getMonth() + 1}`
                : date.getMonth() + 1
            }-${date.getDate()}T00:00:00.000`
          }
        }
      }
      Object.assign(opts, { body: JSON.stringify(body) })
    }
    // Perform API request
    const apiURL = process.env.REACT_APP_API_URL
    console.log('API CALL', `${apiURL}/${path}`, opts)
    const response = await fetch(`${apiURL}/${path}`, opts)
    if (response.status === 400) {
      throw new BadRequestError()
    }
    if (response.status === 401) {
      throw new AuthenticationError()
    }
    // Invalid status codes
    if (response.status === 410) throw new GoneError()
    if (response.status === 403 || response.status === 401)
      throw new ForbiddenError()
    if (response.status === 409) throw new ConflictError()
    if (response.status === 404) throw new NotFoundError()
    if (response.status === 500) throw new InternalServerError()
    if (response.status > 299) {
      console.log('API ERROR', response.status, await response.json())
      throw new Error(`Invalid API response: ${response.body}`)
    }
    if (['DELETE'].indexOf(method) > -1) {
      // Method excepts no response
      return true
    } else {
      const resp = this.parseJSON(response)
      const data = await API.parseResponse(resp)
      console.log(`API RESPONSE for ${apiURL}/${path}`, data)
      return data
    }
  }

  private static async parseResponse(response: any) {
    const data = await response
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        let value = data[key]
        if (typeof value === 'object') {
          data[key] = await API.parseResponse(value)
        } else {
          let value = data[key]
          if (
            /[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{3}Z/gm.exec(
              value
            )
          ) {
            value = new Date(value)
          }
          data[key] = value
        }
      }
    }
    return data
  }

  private static parseJSON = (response: any) => {
    // handle empty responses
    return response.text().then((text: any) => {
      return text ? JSON.parse(text) : {}
    })
  }

  static async get(path: string) {
    return API.request('GET', path)
  }

  static async post(path: string, body?: any) {
    return API.request('POST', path, body)
  }
  static async patch(path: string, body?: any) {
    return API.request('PATCH', path, body)
  }
  static async put(path: string, body?: any) {
    return API.request('PUT', path, body)
  }
  static async delete(path: string) {
    return API.request('DELETE', path)
  }

}
