import moment from 'moment'
import ISlot from '../interfaces/ISlot'
import IBookingConfig from '../interfaces/IBookingConfig'
import API from './API'
import { formatDate } from '../helper/dateHelper'

export default class BookingManager {
  static bookingConfig: Partial<IBookingConfig>

  static async findSlotsForDate(
    locationId: number,
    date: Date,
    duration: number
  ): Promise<ISlot[]> {
    return API.get(
      `locations/${locationId}/slots?startDate=${formatDate(date)}&duration=${
        duration * 60
      }`
    )
  }

  static async findDailySlotsForDate(
    locationId: number,
    startDate: Date,
    numberOfDays: number
  ): Promise<ISlot[]> {
    return API.get(
      `locations/${locationId}/slots?startDate=${formatDate(
        startDate
      )}&numberOfDays=${numberOfDays}`
    )
  }

  private static loadBookingConfig() {
    if (!BookingManager.bookingConfig) {
      const config = localStorage.getItem('bookingConfig')
      if (config) {
        BookingManager.bookingConfig = BookingManager.parseConfig(
          JSON.parse(config)
        )
      }
    }

    return BookingManager.bookingConfig
  }

  private static parseConfig(data: any) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        let value = data[key]
        if (typeof value === 'object') {
          data[key] = BookingManager.parseConfig(value)
        } else {
          let value = data[key]
          if (
            /[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{3}Z/gm.exec(
              value
            )
          ) {
            value = new Date(value)
          }
          data[key] = value
        }
      }
    }
    return data
  }

  static getBookingConfigElement(key: keyof IBookingConfig, defaultValue: any) {
    BookingManager.loadBookingConfig()
    if (
      BookingManager.bookingConfig &&
      BookingManager.bookingConfig[key] !== undefined
    )
      return BookingManager.bookingConfig[key]
    return defaultValue
  }

  static updateBookingConfig(config: Partial<IBookingConfig>) {
    BookingManager.loadBookingConfig()

    BookingManager.bookingConfig = {
      ...BookingManager.bookingConfig,
      ...config,
    }
    localStorage.setItem(
      'bookingConfig',
      JSON.stringify(BookingManager.bookingConfig)
    )
  }

  static getBookingConfig() {
    return BookingManager.loadBookingConfig()
  }

  static getBooking() {
    return API.get('manageBooking')
  }

  static start() {
    return API.patch('manageBooking/start')
  }

  static complete() {
    return API.patch('manageBooking/complete')
  }

  static openBox(number?: number) {
    console.log('OPEN', number)
    return API.patch('manageBooking/openBox', {
      number,
    })
  }

  static getTokenFromSecret(secret: string) {
    return API.get(`secrets/${secret}`)
  }

  static reportBox(
    boxNumber: number,
    message: string,
    usable: boolean,
    damage: boolean
  ) {
    return API.patch('manageBooking/reportBox', {
      boxNumber,
      usable,
      damage,
      message,
    })
  }

  static extend(duration: number) {
    return API.patch('manageBooking/extend', {
      duration,
    })
  }

  static extensions() {
    return API.get('manageBooking/extensions')
  }

  static cancel() {
    return API.patch('manageBooking/cancel')
  }

  static checkDiscountCode(code: string) {
    return API.get(`discounts/${code}`)
  }
}
