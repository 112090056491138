import React from 'react'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props {
  children: any
  hideBackButton?: boolean
}

export default function Layout({ children, hideBackButton }: Props) {
  return (
    <div>
      <div
        style={{
          padding: 20,
          backgroundColor: '#89AEB3',
          textAlign: 'center',
        }}
      >
        {!hideBackButton && (
          <FontAwesomeIcon
            icon={faChevronLeft}
            size="2x"
            color="#fff"
            style={{
              position: 'absolute',
              left: 20,
              top: 28,
              cursor: 'pointer',
            }}
            onClick={() => {
              window.history.back()
            }}
          />
        )}

        <img
          src="https://static.wixstatic.com/media/79d037_c960cb9f442b473080931455b0e892d3~mv2.png/v1/fill/w_480,h_82,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/SUP%20DUDES%20Gaffiti%20Schriftzug_petrol_frei.png"
          alt="SUP DUDES"
          style={{ width: 240 }}
        />
      </div>
      <br />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            width: '100%',
            maxWidth: 800,
          }}
        >
          {children}
        </div>
      </div>
    </div>
  )
}
