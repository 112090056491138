import React, { useState } from 'react'
import styles from './Rating.module.scss'
import { Rating as RatingComp } from '@material-ui/lab'
import { Button } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebookSquare,
  faGoogle,
  faInstagramSquare,
} from '@fortawesome/free-brands-svg-icons'

const Rating = () => {
  const [value, setValue] = useState<number>(5)
  const [rated, setRated] = useState<boolean>(false)

  return (
    <div className={styles.body}>
      {rated ? (
        value === 5 ? (
          <>
            <div className={styles.h1}>
              Es freut uns, dass Du so zufrieden mit bist. Wir würden uns
              freuen, wenn Du Deine Erfahung mit anderen teilst.
            </div>

            <div className={styles.iconContainer}>
              <FontAwesomeIcon icon={faFacebookSquare} />
              <FontAwesomeIcon icon={faGoogle} />
              <FontAwesomeIcon icon={faInstagramSquare} />
            </div>
          </>
        ) : (
          <div className={styles.h1}>Danke für deine Bewertung</div>
        )
      ) : (
        <>
          <div className={styles.h1}>
            Teile uns mit, wie zufrieden Du warst?
          </div>
          <RatingComp
            size={'large'}
            name="half-rating"
            defaultValue={2.5}
            precision={0.5}
            onChange={(event, newValue) => {
              if (newValue) setValue(newValue)
            }}
          />
          <Button className={styles.button} onClick={() => setRated(true)}>
            Bewertung abgeben
          </Button>
        </>
      )}
    </div>
  )
}

export default Rating
