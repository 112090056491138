import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import LocationManager from '../services/LocationManager'
import { default as LocationInterface } from '../interfaces/ILocation'
import Loading from '../components/Loading'
import { Button, Container, Slider } from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import ISlot from '../interfaces/ISlot'
import BookingManager from '../services/BookingManager'
import Slots from '../components/Slots'
import 'moment/locale/de'
import moment from 'moment'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import { BookingMode } from '../enums/BookingMode'
import styles from './Location.module.scss'
import InfoText from '../components/InfoText'
import Layout from '../components/Layout'

interface Props {
  locationId?: number
}

export default function Location({ locationId }: Props) {
  let { id: idFromUrl } = useParams<{ id: string }>()

  useEffect(() => {
    if (idFromUrl) {
      setId(parseInt(idFromUrl))
    }
    if (locationId) {
      setId(locationId)
    }
  }, [idFromUrl, locationId])

  const [id, setId] = useState<number>()

  const [location, setLocation] = useState<LocationInterface>()
  const [mode, setMode] = useState<number>(
    BookingManager.getBookingConfigElement('mode', BookingMode.HOUR)
  )

  const [duration, setDuration] = useState<number>(
    BookingManager.getBookingConfigElement('duration', 1)
  )
  const [numberOfPersons, setNumberOfPersons] = useState<number>(
    BookingManager.getBookingConfigElement('numberOfBoxes', 1)
  )
  const [startDate, setStartDate] = useState<Date>(
    new Date(BookingManager.getBookingConfigElement('startDate', new Date()))
  )
  const [numberOfDays, setNumberOfDays] = useState<number>(
    BookingManager.getBookingConfigElement('numberOfDays', 1)
  )

  const [slots, setSlots] = useState<ISlot[]>([])
  const [slot, setSlot] = useState<ISlot | undefined>()

  useEffect(() => {
    if (!id) return
    ;(async () => {
      const loc = await LocationManager.findById(id)
      setLocation(loc)

      if (duration < (loc.minHours || 1)) setDuration(loc.minHours || 1)

      if (!loc.dailyRentalEnabled && mode === BookingMode.DAY) {
        setMode(BookingMode.HOUR)
        setSlots(await BookingManager.findSlotsForDate(id, startDate, duration))
      } else {
        if (mode === BookingMode.DAY) {
          setSlots(
            await BookingManager.findDailySlotsForDate(
              id,
              startDate,
              numberOfDays
            )
          )
        } else {
          setSlots(
            await BookingManager.findSlotsForDate(id, startDate, duration)
          )
        }
      }
    })()
  }, [id])

  useEffect(() => {
    if (!id) return
    ;(async () => {
      if (mode === BookingMode.HOUR) {
        setSlots(await BookingManager.findSlotsForDate(id, startDate, duration))
      } else {
        setSlots(
          await BookingManager.findDailySlotsForDate(
            id,
            startDate,
            numberOfDays
          )
        )
      }
    })()
  }, [id, startDate, duration, numberOfPersons, mode, numberOfDays])

  useEffect(() => {
    if (slot && numberOfPersons > slot.amount) {
      setSlot(undefined)
    }
  }, [slot, numberOfPersons])

  useEffect(() => {
    BookingManager.updateBookingConfig({
      location,
      mode,
      duration,
      numberOfBoxes: numberOfPersons,
      startDate,
      numberOfDays,
      slot,
    })
  }, [location, mode, duration, numberOfPersons, startDate, numberOfDays, slot])

  useEffect(() => {
    if (slots) {
      setSlot(slots[0])
    }
  }, [slots])

  if (!location)
    return (
      <Layout>
        <Loading />
      </Layout>
    )
  return (
    <Layout>
      <MuiPickersUtilsProvider utils={MomentUtils} locale="de">
        <Container className={styles.container}>
          <div className={styles.h1}>Buchung</div>
          <div className={styles.h2}>{location.name}</div>

          {location.dailyRentalEnabled && (
            <div>
              <div className={styles.navHeader}>
                Klicke links für SUP/Kajak-Miete je Stunde oder rechts für ganze Tage.
              </div>
              <div className={styles.navBody}>
                <div
                  onClick={() => setMode(BookingMode.HOUR)}
                  style={{
                    color: mode === BookingMode.HOUR ? '#fff' : '',
                    backgroundColor:
                      mode === BookingMode.HOUR ? '#89AEB3' : 'inherit',
                  }}
                  className={styles.navPart}
                >
                  Stundenmiete
                </div>

                <div
                  onClick={() => setMode(BookingMode.DAY)}
                  style={{
                    color: mode === BookingMode.DAY ? '#fff' : '',
                    backgroundColor:
                      mode === BookingMode.DAY ? '#89AEB3' : 'inherit',
                  }}
                  className={styles.navPart}
                >
                  24 Std. Miete
                </div>
              </div>
            </div>
          )}

          {location.dailyRentalEnabled && mode === BookingMode.DAY ? (
            <div
              style={{
                fontSize: 15,
                textAlign: 'center',
                marginTop: 10,
              }}
            >
              Preis pro Tag: {location.dailyRate.toFixed(0)} €
            </div>
          ) : (
            <div
              style={{
                fontSize: 15,
                textAlign: 'center',
                marginTop: 10,
              }}
            >
              Preis pro Stunde: {location.hourlyRate.toFixed(0)} €
            </div>
          )}

          <div className={styles.sliderContainer}>
            <div className={styles.sliderText}>
              <div className={styles.sliderHeader}>Anzahl SUP-Boards/Kajaks</div>
              <InfoText>
                Verschiebe den Punkt, um die Anzahl an SUP-Boards/Kajaks zu verändern
              </InfoText>
              <br />
              <br />

              <Slider
                aria-labelledby="discrete-slider"
                valueLabelDisplay="on"
                className={styles.slider}
                step={1}
                min={1}
                max={8}
                value={numberOfPersons}
                onChange={(e, v) => setNumberOfPersons(v as number)}
              />
            </div>
            {mode === BookingMode.HOUR && (
              <div
                className={styles.sliderText}
                style={{
                  marginTop: 30,
                }}
              >
                <div className={styles.sliderHeader}>Stundenanzahl</div>
                <InfoText>
                  Verschiebe den Punkt, um die Mietdauer zu verändern
                </InfoText>
                <br />
                <br />

                <Slider
                  aria-labelledby="discrete-slider"
                  valueLabelDisplay="on"
                  className={styles.slider}
                  step={location?.lockType === 'IGLOO' ? 1 : 0.5}
                  min={location?.minHours || 1}
                  max={8}
                  value={duration}
                  onChange={(e, v) => setDuration(v as number)}
                  valueLabelFormat={(val) => location?.lockType === "IGLOO" ? `${val-1}:45` : val}
                />
              </div>
            )}
          </div>
          {mode === BookingMode.HOUR && (
            <>
              <div className={styles.calenderContainer}>
                <InfoText>
                  Wähle hier das Datum & eine verfügbare Startzeit:
                </InfoText>

                <DatePicker
                  autoOk
                  orientation="landscape"
                  variant="static"
                  openTo="date"
                  value={startDate}
                  onChange={(newDate) =>
                    newDate && setStartDate(newDate.toDate())
                  }
                  disablePast={true}
                  disableToolbar={true}
                  maxDate={moment().add(6, 'days')}
                />
              </div>
            </>
          )}

          {location.dailyRentalEnabled && mode === BookingMode.DAY && (
            <div style={{ width: '100%', textAlign: 'center' }}>
              <InfoText>
                Wähle hier ab welchem Datum Du ein SUP/Kajak mieten möchtest:
              </InfoText>

              <DatePicker
                autoOk
                orientation="landscape"
                variant="static"
                openTo="date"
                value={startDate}
                onChange={(newDate) =>
                  newDate && setStartDate(newDate.toDate())
                }
                disablePast={true}
                disableToolbar={true}
                maxDate={moment().add(6, 'days')}
              />

              <div className={styles.sliderHeader}>Anzahl Tage</div>
              <InfoText>
                Verschiebe den Punkt, um die Anzahl der Tage zu verändern
              </InfoText>
              <br />
              <br />

              <Slider
                aria-labelledby="discrete-slider"
                valueLabelDisplay="on"
                className={styles.slider}
                step={1}
                min={1}
                max={3}
                value={numberOfDays}
                onChange={(e, v) => setNumberOfDays(v as number)}
              />
            </div>
          )}

          <div className={styles.slotContainer}>
            <div className={styles.slotHeader}>
              Hier den Startzeitpunkt wählen
            </div>
            <Slots
              slots={slots}
              slot={slot}
              setSlot={setSlot}
              numberOfPersons={numberOfPersons}
            />
            {slot && slot.end && (
              <>
                <div className={styles.end}>Deine Mietzeit endet um:</div>
                <div className={styles.endTime}>
                  {mode === BookingMode.DAY && (
                    <span>{moment(slot?.end).format('DD.MM.YYYY')},&nbsp;</span>
                  )}
                  {slot?.end.getMinutes() === 0
                    ? Math.floor(slot?.end.getHours() - 0.25)
                    : slot.end.getHours()}
                  :
                  {slot?.end.getMinutes() === 0
                    ? '45'
                    : slot.end.getMinutes() === 15
                    ? slot.end.getMinutes() - 15 + '0'
                    : slot.end.getMinutes() - 15}
                  &nbsp;Uhr
                </div>
              </>
            )}
          </div>

          <div className={styles.buttonContainer}>
            {(slot || (startDate && mode === BookingMode.DAY)) && (
              <Button
                style={{ backgroundColor: ' #FEB8A1' }}
                className={styles.button}
                variant="contained"
                component={Link}
                to="/booking"
              >
                Weiter zur Buchung
              </Button>
            )}
          </div>
        </Container>
      </MuiPickersUtilsProvider>
    </Layout>
  )
}
