import React from 'react'
import ILocation from '../interfaces/ILocation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarker, faTimes } from '@fortawesome/pro-solid-svg-icons'
import styles from './MapMarker.module.scss'
import { Button } from '@material-ui/core'
import { Link } from 'react-router-dom'

interface Props {
  location: ILocation
  lat: number
  lng: number
  showDetails: boolean
  onOpen: () => void
  onClose: () => void
}

export default function MapMarker({
  location,
  showDetails,
  onOpen,
  onClose,
}: Props) {
  if (showDetails)
    return (
      <div className={styles.tooltip} onClick={onClose}>
        <FontAwesomeIcon
          onClick={onClose}
          icon={faTimes}
          size="2x"
          className={styles.closeIcon}
        />

        <div className={styles.content}>
          <strong>{location.name}</strong>
          <div>{location.description}</div>
          <div>
            <Button
              color="primary"
              component={Link}
              to={`/location/${location.id}`}
            >
              Jetzt SUP/Kajak buchen
            </Button>
          </div>
        </div>
      </div>
    )

  return (
    <FontAwesomeIcon
      onClick={onOpen}
      icon={faMapMarker}
      size="4x"
      className={styles.marker}
    />
  )
}
