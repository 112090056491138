import React, { useEffect, useState } from 'react'
import GoogleMapReact from 'google-map-react'
import ILocation from '../interfaces/ILocation'
import MapMarker from '../components/MapMarker'
import LocationManager from '../services/LocationManager'

export default function Map() {
  const [map, setMap] = useState<any>(undefined)
  const [locations, setLocations] = useState<ILocation[]>([])
  const [currentLocation, setCurrentLocation] = useState<number | undefined>(
    undefined
  )

  const defaultProps = {
    center: {
      lat: 53.5438527,
      lng: 10.0430273,
    },
    zoom: 10,
  }

  const key = 'AIzaSyANbX_3AfnQxCyzmyAIT3Obk69tzuFd8IU' // TODO: change

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (map) {
          map.setCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          })
          map.setZoom(14)
        }
      })
    }
  }, [map])

  useEffect(() => {
    ;(async () => {
      setLocations(await LocationManager.findAll())
    })()
  }, [])

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => setMap(map)}
      >
        {locations.map((location, key) => {
          return (
            <MapMarker
              key={key}
              lat={location.coordinates.lat}
              lng={location.coordinates.lng}
              location={location}
              showDetails={location.id === currentLocation}
              onOpen={() => setCurrentLocation(location.id)}
              onClose={() => setCurrentLocation(undefined)}
            />
          )
        })}
      </GoogleMapReact>
    </div>
  )
}
