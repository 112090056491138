import React, { useState } from 'react'
import styles from './BoxStateForm.module.scss'
import { Button, Input } from '@material-ui/core'

interface Props {
  onChangeState: (
    damaged?: boolean,
    usable?: boolean,
    description?: string
  ) => void
  boxClosed: boolean
}

enum Status {
  ok = 'ok',
  usable = 'usable',
  notUsable = 'notUsable',
}

const BoxStateForm = ({ boxClosed, onChangeState }: Props) => {
  const [status, setStatus] = useState<Status>()
  const [description, setDescription] = useState<string>('')

  const handleSave = () => {
    if (status === Status.ok) onChangeState(false, true, '')
    if (status === Status.usable) onChangeState(true, true, description)
    if (status === Status.notUsable) onChangeState(true, false, description)
  }

  return (
    <div className={styles.blur}>
      <div className={styles.box}>
        <div className={styles.h1}>
          Bitte gib uns kurz ein Feedback zum Zustand des Materials:
        </div>

        <div className={styles.buttons}>
          <div
            onClick={() => setStatus(Status.ok)}
            className={`${styles.button} ${
              status === Status.ok ? styles.buttonActive : ''
            }`}
          >
            Alles ok
          </div>
          <div
            onClick={() => setStatus(Status.usable)}
            className={`${styles.button} ${
              status === Status.usable ? styles.buttonActive : ''
            }`}
          >
            Beschädigt, aber nutzbar
          </div>
          <div
            onClick={() => setStatus(Status.notUsable)}
            className={`${styles.button} ${
              status === Status.notUsable ? styles.buttonActive : ''
            }`}
          >
            Defekt, nicht nutzbar
          </div>
        </div>

        {(status === Status.notUsable || status === Status.usable) && (
          <Input
            multiline
            placeholder={'Beschreibe uns kurz, was defekt / beschädigt ist'}
            rows={4}
            value={description}
            onChange={(e: any) => setDescription(e.target.value)}
            style={{
              borderRadius: 5,
              padding: 10,
              backgroundColor: 'white',
              border: 'solid',
              borderColor: '#00000080',
              borderWidth: 1,
              marginBottom: 10,
            }}
          />
        )}

        {status === Status.notUsable && (
          <p>
            Bitte packe alles zurück in die Box und schließe sie. Wir versuchen
            Dir anschließend ein neues Boards zuzuweisen.
            <br />
            <strong>
              Achte auf die Nummer der Box, Du bekommst eine neue zugewiesen!
            </strong>
          </p>
        )}

        <div className={styles.actionButtons}>
          {status && (
            <Button className={styles.button} onClick={handleSave}>
              {status === Status.notUsable && boxClosed && (
                <span>Neues Board anfragen</span>
              )}
              {status === Status.usable && <span>Schaden melden und los</span>}
              {status === Status.ok && <span>Los geht's</span>}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default BoxStateForm
