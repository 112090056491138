import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/pro-solid-svg-icons'
import styles from './Loading.module.scss'

export default function Loading() {
  return (
    <div className={styles.container}>
      <FontAwesomeIcon icon={faSpinner} spin size="4x" />
    </div>
  )
}
