import IBox from '../interfaces/IBox'
import Box from './Box'
import { Grid } from '@material-ui/core'
import ILocation from "../interfaces/ILocation";

interface Props {
  boxes: IBox[]
  location: ILocation;
  onOpen: (number: number) => void
}

const Boxes = ({ boxes, onOpen, location }: Props) => {
  return (
    <Grid
      container
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {boxes.map((b, key) => (
        <Box
          key={key}
          box={b}
          onOpen={() => {
            return onOpen(b.number)
          }}
          location={location}
        />
      ))}
    </Grid>
  )
}
export default Boxes
