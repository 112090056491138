import NotFoundError from '../errors/NotFoundError'
import BadRequestError from '../errors/BadRequestError'
import ConflictError from '../errors/ConflictError'
import GoneError from '../errors/GoneError'
import InternalServerError from '../errors/InternalServerError'
import AuthenticationError from '../errors/AuthenticationError'
import ForbiddenError from '../errors/ForbiddenError'

export interface ApiErrorOptions {
  onNotFoundError?: () => void
  onBadRequestError?: () => void
  onConflictError?: () => void
  onGoneError?: () => void
  onTooEarlyError?: () => void
  onInternalServerError?: () => void
  onUnauthorizedError?: () => void
  onForbiddenError?: () => void
  default?: () => void
}

export default function apiErrorHandler(
  error: Error,
  options: ApiErrorOptions
) {
  if (error instanceof NotFoundError && options.onNotFoundError)
    return options.onNotFoundError()

  if (error instanceof BadRequestError && options.onBadRequestError)
    return options.onBadRequestError()

  if (error instanceof ConflictError && options.onConflictError)
    return options.onConflictError()

  if (error instanceof GoneError && options.onGoneError)
    return options.onGoneError()

  if (error instanceof InternalServerError && options.onInternalServerError)
    return options.onInternalServerError()

  if (error instanceof AuthenticationError && options.onUnauthorizedError)
    return options.onUnauthorizedError()

  if (error instanceof ForbiddenError && options.onForbiddenError)
    return options.onForbiddenError()

  if (options.default) return options.default()

  throw error
}
