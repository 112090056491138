import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Map from './pages/Map'
import Location from './pages/Location'
import Booking from './pages/Booking'
import ManageBooking from './pages/ManageBooking'
import './style.scss'
import FinishBooking from './pages/FinishBooking'

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/location/:id">
          <Location />
        </Route>
        <Route path="/booking">
          <Booking />
        </Route>
        <Route path="/finishBooking">
          <FinishBooking />
        </Route>
        <Route path="/manageBooking">
          <ManageBooking />
        </Route>
        <Route path="/">
          <Map />
        </Route>
      </Switch>
    </Router>
  )
}

export default App
