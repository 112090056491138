import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import IBooking from './pages/Booking'
import FinishBooking from './pages/FinishBooking'
import ManageBooking from './pages/ManageBooking'
import Location from './pages/Location'

interface WidgetParameters {
  containerElement: unknown
  widget: string
}

const widgetRoot = document.getElementById('dtsWidgetScript')
const widget = widgetRoot || process.env.REACT_APP_WIDGET
if (widget) {
  // Load widget
  const win = window as any

  const getWidgetComponent = (name: string, parameters: any) => {
    if (name === 'location') return <Location locationId={parameters.id} />
    if (name === 'booking') return <IBooking />
    if (name === 'finishBooking') return <FinishBooking />
    if (name === 'manageBooking') return <ManageBooking />

    return undefined
  }

  if (widgetRoot) {
    // Load widget parameters
    const queue = win.dtsWidget.q
    win.dtsWidget = (parameters: WidgetParameters) => {
      const component = getWidgetComponent(parameters.widget, parameters)
      if (component) {
        ReactDOM.render(
          <React.StrictMode>{component}</React.StrictMode>,
          parameters.containerElement as any
        )
      }
    }
    queue.forEach((parameters: [WidgetParameters]) => {
      win.dtsWidget(parameters[0])
    })
  } else if (process.env.REACT_APP_WIDGET) {
    // Dev mode --> Render widget in root element
    const component = getWidgetComponent(process.env.REACT_APP_WIDGET, {})
    if (component) {
      ReactDOM.render(
        <React.StrictMode>{component}</React.StrictMode>,
        document.getElementById('root')
      )
    }
  }
} else {
  // Load client

  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  )
}
