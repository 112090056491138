import React from 'react'
import styles from './Slots.module.scss'
import { Grid, Paper } from '@material-ui/core'
import ISlot from '../interfaces/ISlot'
import { formatTime } from '../helper/dateHelper'

interface Props {
  slots: ISlot[]
  slot: ISlot | undefined
  setSlot: (slot: ISlot) => void
  numberOfPersons: number
}

export default function Slots({
  slot,
  slots,
  setSlot,
  numberOfPersons,
}: Props) {
  console.log(slot?.start.toLocaleTimeString())
  console.log(slot?.end.toLocaleTimeString())
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      {slots.map((s, key) => (
        <Grid item xs={6} md={4} key={key}>
          <div
            className={[
              styles.slot,
              slot && slot.id === s.id ? styles.active : '',
              numberOfPersons > s.amount ? styles.lowAmount : '',
              s.amount === 0 ? styles.fullyBooked : '',
            ].join(' ')}
            onClick={() => setSlot(s)}
          >
            {formatTime(s.start)}
          </div>
        </Grid>
      ))}
    </Grid>
  )
}
