import React from 'react'
import styles from './InfoText.module.scss'

interface Props {
  children: any
}

export default function InfoText({ children }: Props) {
  return <div className={styles.container}>{children}</div>
}
