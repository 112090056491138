import ILocation from '../interfaces/ILocation'
import API from './API'

export default class LocationManager {
  static async findById(id: number): Promise<ILocation> {
    return API.get(`locations/${id}`)
  }

  static async findAll(): Promise<ILocation[]> {
    return API.get('locations')
  }
}
