import IBox from '../interfaces/IBox'
import { BoxState } from '../enums/BoxState'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faLockOpen } from '@fortawesome/pro-regular-svg-icons'
import { Grid } from '@material-ui/core'
import styles from './Box.module.scss'
import ILocation from "../interfaces/ILocation";

interface Props {
  box: IBox;
  location: ILocation;
  onOpen: () => void
}

const Box = ({ box, onOpen, location }: Props) => {
  return (
    <Grid item xs={12} className={styles.body}>
      <div className={styles.textContainer}>
        {box.state === BoxState.CLOSED ? (
          <FontAwesomeIcon
            icon={faLock}
            size={'2x'}
            className={styles.iconContainer}
          />
        ) : (
          <FontAwesomeIcon
            icon={faLockOpen}
            size={'2x'}
            className={styles.iconContainer}
          />
        )}
        <div className={styles.text}>Boxnummer: {box.number}</div>
      </div>
      {box.state === BoxState.CLOSED && (
        <button onClick={onOpen} className={styles.openButton}>
          {location.lockType === 'IGLOO' ? 'PIN abrufen' : 'Öffnen'}
        </button>
      )}
    </Grid>
  )
}
export default Box
