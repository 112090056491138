import React, { useEffect, useState } from 'react'
import BookingManager from '../services/BookingManager'
import { formatDate } from '../helper/dateHelper'
import API from '../services/API'
import Loading from '../components/Loading'
import apiErrorHandler from '../services/ApiErrorHandler'
import { BookingMode } from '../enums/BookingMode'
import Swal from 'sweetalert2'
import Layout from '../components/Layout'

export default function FinishBooking() {
  const config = BookingManager.getBookingConfig()

  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    ;(async () => {
      if (!config.location) {
        Swal.fire({
          icon: 'error',
          title: 'Ups...',
          text: 'Leider ist bei der Buchung etwas schiefgelaufen. Bitte versuche es noch einmal.',
        })

        return
      }

      await setLoading(true)

      const payload = {
        firstName: config.firstName,
        lastName: config.lastName,
        email: config.email,
        mobile: config.mobile,
        phoneNumber: config.mobile,
        numberOfBoxes: config.numberOfBoxes,
        notificationChannel: config.notificationChannel,
        startDate: formatDate(config.startDate),
        paymentType:
          config.paymentMethod === 'free' ? 'stripe' : config.paymentMethod,
        paymentId: config.paymentId,
        discountCode:
          config.discountCode && config.discountCode.trim() === ''
            ? null
            : config.discountCode,
      } as any

      if (config.mode === BookingMode.HOUR && config.slot) {
        payload.startTime = config.slot.id
        payload.duration = config.duration ? config.duration * 60 : 0
      } else if (config.mode === BookingMode.DAY && config.slot) {
        payload.startTime = config.slot.id
        payload.numberOfDays = config.numberOfDays
      } else throw new Error('Invalid booking mode')

      try {
        await API.post(`locations/${config.location.id}/bookings`, payload)

        window.location.href = 'https://sup-dudes.com/buchungsbestaetigung'
      } catch (e) {
        setLoading(false)

        apiErrorHandler(e, {
          onConflictError: () => {
            Swal.fire({
              icon: 'error',
              title: 'Da war jemand schneller...',
              text: 'Leider stehen zu der von Dir gewünschten Zeit nicht mehr ausreichend SUP-Boards/Kajaks zur Verfügung.',
            }).then(() => {
              window.location.href = `/location/${config.location?.id}`
            })
          },
          onGoneError: () => {
            Swal.fire({
              icon: 'error',
              title: 'Da war jemand schneller...',
              text: 'Leider stehen zu der von Dir gewünschten Zeit nicht mehr ausreichend SUP-Boards/Kajaks zur Verfügung.',
            }).then(() => {
              window.location.href = `/location/${config.location?.id}`
            })
          },
          default: () => {
            Swal.fire({
              icon: 'error',
              title: 'Ups...',
              text: 'Leider ist bei der Buchung etwas schiefgelaufen. Bitte versuche es noch einmal.',
            }).then(() => {
              window.location.href = `/location/${config.location?.id}`
            })
          },
        })
      }
    })()
  }, [])

  if (loading)
    return (
      <Layout>
        <Loading />
      </Layout>
    )

  return (
    <Layout>
      <div />
    </Layout>
  )
}
