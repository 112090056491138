import React from 'react'
import styles from './PinCodePopover.module.scss'
import {faSpinner} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface Props {
  pinCode?: string;
  onClose: () => void;
  isOneTimePin?: boolean;
}

const PinCodePopover = ({pinCode, onClose, isOneTimePin}: Props) => {
  return (
    <div className={styles.blur}>
      <div className={styles.container}>

        <div className={styles.image}>
          <img src="/pin-code-lock.png"/>
        </div>

        {pinCode === '' ? <div>
          <FontAwesomeIcon icon={faSpinner} spin size="4x" />
        </div> : (
          <>

            <div className={styles.stepTitle}>
              1) PIN eingeben:
            </div>

            <div className={styles.pinCode}>
              {pinCode?.split('').map((c, i) => (
                <span key={i}>{c}{i % 3 === 2 && i !== pinCode?.length - 1 && ' '}</span>
              ))}
            </div>

            <div className={styles.stepTitle}>
              2) Schloss-Symbol drücken
            </div>

            {isOneTimePin && (
              <div style={{
                color: '#ce0d0d',
                fontWeight: 'bold',
                marginTop: 10,
                marginBottom: 10,
              }}>
                Dieser PIN ist nur für eine Öffnung gültig!
              </div>
            )}

            <button onClick={onClose} className={styles.button}>Fertig</button>

          </>
        )}

      </div>
    </div>
  )
}

export default PinCodePopover