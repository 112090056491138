import React, { useEffect, useState } from 'react'
import styles from './BoxStateForm.module.scss'
import { Button, Input } from '@material-ui/core'
import BookingManager from '../services/BookingManager'
import IBooking from '../interfaces/IBooking'
import moment from 'moment'

interface Props {
  onClose: () => void
  booking?: IBooking
}

const ExtensionForm = ({ onClose, booking }: Props) => {
  const [selected, setSelected] = useState<number>()
  const [extensions, setExtensions] = useState<number[]>([])

  useEffect(() => {
    ;(async () => {
      setExtensions(
        (await BookingManager.extensions()).durations.sort(
          (a: number, b: number) => (a > b ? 1 : -1)
        )
      )
    })()
  }, [])

  const handleSave = async () => {
    if (!selected) return
    await BookingManager.extend(selected)
    window.location.reload()
  }

  return (
    <div className={styles.blur}>
      <div className={styles.box}>
        <div className={styles.h1}>
          Wie lange möchtest Du Deine Miete verlängern?
        </div>

        <div className={styles.buttons}>
          {extensions.map((ext) => (
            <div
              key={ext}
              onClick={() => setSelected(ext)}
              className={`${styles.button} ${
                ext === selected ? styles.buttonActive : ''
              }`}
            >
              bis {moment(booking?.endDate).add(ext, 'minutes').format('HH:mm')}{' '}
              Uhr
            </div>
          ))}
        </div>

        {extensions.length === 0 && (
          <p>Leider kann Deine Miete nicht verlängert werden.</p>
        )}

        <div className={styles.actionButtons}>
          <Button className={styles.button} onClick={onClose}>
            Abbrechen
          </Button>
          {selected && (
            <Button className={styles.button} onClick={handleSave}>
              Miete verlängern
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default ExtensionForm
