import React from 'react'
import { Container } from '@material-ui/core'
import styles from './EndedBooking.module.scss'
import Rating from '../components/Rating'

const EndedBooking = () => {
  return (
    <Container className={styles.container}>
      <div className={styles.h1}>Buchung</div>
      <div className={styles.h2}>
        Danke, dass Du ein SUP/Kajak von uns gemietet hast!
      </div>
      <Rating />
    </Container>
  )
}

export default EndedBooking
